import {
  createSchemaReqExn,
  CreateSchemaReqError,
  getBatchesExn,
  GetBatchesError,
  getBatchByIDExn,
  GetBatchByIDError,
  createBatchReviewExn,
  CreateBatchReviewError,
} from '__generated__/__services__/rg_data_cms_api/RgDataCmsApi';

import { CommonCreateOrEditResponse } from '../event/event';

import {
  CreateBatchReviewRequest,
  CreateSchemaRequest,
  GetBatchesResponse,
  GetBatchRequest,
  GetBatchByIDFilterRequest,
  GetBatchByIDResponse,
} from './batch';

const createSchema = async (
  payload: CreateSchemaRequest,
): Promise<CommonCreateOrEditResponse> => {
  return createSchemaReqExn(payload);
};

const getBatches = async (
  payload: GetBatchRequest,
): Promise<GetBatchesResponse> => {
  return getBatchesExn(payload);
};

const getBatchByID = async (
  payload: GetBatchByIDFilterRequest,
): Promise<GetBatchByIDResponse> => {
  return getBatchByIDExn(payload);
};

const createBatchReview = async (
  payload: CreateBatchReviewRequest,
): Promise<CommonCreateOrEditResponse> => {
  return createBatchReviewExn(payload);
};

export { createSchema, getBatches, getBatchByID, createBatchReview };
export type {
  CreateSchemaReqError,
  GetBatchesError,
  GetBatchByIDError,
  CreateBatchReviewError,
};
