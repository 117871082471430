import { Box } from '@chakra-ui/react';
import { GetTrackingGroupResponse, TrackingGroup } from 'modules/event/event';
import { useMemo } from 'react';
import { Column } from 'react-table';
import Link from 'next/link';

import { CustomTable } from '../Component/Table';

type Props = {
  data?: GetTrackingGroupResponse;
  isLoading: boolean;
  refetch: () => void;
};

type Data = Partial<TrackingGroup>;

function GroupTrackingTable({ data, isLoading }: Props) {
  const customData: Array<Data> =
    data?.groups.map((group) => {
      return {
        ...group,
      };
    }) ?? [];

  const columns = useMemo<Array<Column<Data>>>(
    () => [
      {
        Header: 'Group',
        accessor: (row) => row,
        maxWidth: 200,
        Cell: ({ cell: value }: { cell: { value: TrackingGroup } }) => {
          return (
            <Link
              href={`/group-tracking?product=${value.value.productId}&feature=${value.value.featureId}&status=ALL_STATUS`}
            >
              <a>{value.value.group}</a>
            </Link>
          );
        },
      },
      {
        Header: 'Product',
        accessor: 'productName',
        maxWidth: 200,
      },
      {
        Header: 'Feature',
        accessor: 'featureName',
        maxWidth: 200,
      },
    ],
    [],
  );

  return (
    <Box>
      <CustomTable<Data>
        metadata={data?.metadata}
        columns={columns}
        data={customData}
        isLoading={isLoading}
      />
    </Box>
  );
}

export { GroupTrackingTable };
