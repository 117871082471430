import {
  UseQueryOptions,
  useQuery,
  UseMutationOptions,
  useMutation,
} from 'react-query';

import {
  GetAutocompleteRequest,
  AutoCompleteResopnse,
  CreateProductRequest,
  CreateFeatureRequest,
} from './common';
import {
  createFeature,
  CreateFeatureError,
  createProduct,
  CreateProductError,
  getAutoComplete,
  GetAutocompleteError,
} from './commonService';

type AutoCompleteKey = Array<string | GetAutocompleteRequest>;

function useGetAutoComplete<TData = AutoCompleteResopnse>(
  payload: GetAutocompleteRequest,
  options?: UseQueryOptions<
    AutoCompleteResopnse,
    GetAutocompleteError,
    TData,
    AutoCompleteKey
  >,
) {
  return useQuery(
    ['autoComplete', payload],
    () => getAutoComplete(payload),
    options,
  );
}

function useCreateProduct(
  options?: UseMutationOptions<
    unknown,
    CreateProductError,
    CreateProductRequest
  >,
) {
  return useMutation(
    (payload: CreateProductRequest) => createProduct(payload),
    options,
  );
}

function useCreateFeature(
  options?: UseMutationOptions<
    unknown,
    CreateFeatureError,
    CreateFeatureRequest
  >,
) {
  return useMutation(
    (payload: CreateFeatureRequest) => createFeature(payload),
    options,
  );
}

export { useGetAutoComplete, useCreateProduct, useCreateFeature };
