import { VStack } from '@chakra-ui/layout';
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useRouter } from 'next/dist/client/router';
import { FcPlus } from '@react-icons/all-files/fc/FcPlus';

import { GroupTrackingTable } from '../GroupTracking/GroupTrackingTable';

// import { NewEventDrawer } from './NewEventDrawer';
import { EventTable } from './EventTable';
import { EventFilter } from './EventFilter';
import { BatchList } from './BatchList';

import {
  useGetAllEvent,
  useGetTrackingGroups,
} from '@/modules/event/eventHooks';
import { ProposalStatus } from '@/modules/proposal/proposal';
import { FilterLegacyMapping } from '@/modules/event/event';

export type QueryParams = {
  pageSize: string;
  page: string;
  tab: string;
};

function HomeContainer() {
  const router = useRouter();
  const query = router.query as unknown as QueryParams;

  const { data, isLoading, isFetching, refetch } = useGetAllEvent({
    limit: parseInt(query.pageSize, 10) || 10,
    page: parseInt(query.page, 10) || 1,
    eventName: (router.query.eventName as string) || '',
    createdBy: (router.query.createdBy as string) || '',
    featureName: (router.query.featureName as string) || '',
    productName: (router.query.productName as string) || '',
    eventStatus: (router.query.eventStatus as ProposalStatus) || 'ALL_STATUS',
    isLegacy:
      (router.query.isLegacy as FilterLegacyMapping) ||
      'FILTER_LEGACY_MAPPING_ALL',
  });

  const {
    data: dataTrackingGroups,
    isLoading: isLoadingTrackingGroups,
    isFetching: isFetchingTrackingGroups,
    refetch: refetchTrackingGroups,
  } = useGetTrackingGroups({
    limit: parseInt(query.pageSize, 10) || 10,
    page: parseInt(query.page, 10) || 1,
  });

  const currentTab = () => {
    if (query.tab === 'event') {
      return 0;
    }
    if (query.tab === 'group') {
      return 1;
    }
    if (query.tab === 'batchList') {
      return 2;
    }
    return 0;
  };

  return (
    <VStack>
      <Tabs
        isLazy
        defaultIndex={currentTab()}
        index={currentTab()}
        onChange={(currentTab) => {
          const tab = () => {
            switch (currentTab) {
              case 0:
                return 'event';
              case 1:
                return 'group';
              case 2:
                return 'batchList';
            }
          };

          router.push({
            pathname: '/',
            query: {
              pageSize: 10,
              page: 1,
              tab: tab(),
            },
          });
        }}
        isFitted
        variant="enclosed"
        width={'100%'}
      >
        <TabList>
          <Tab>Event</Tab>
          <Tab>Groups</Tab>
          <Tab>Change Request</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {/* <NewEventDrawer onSubmited={refetch} type="Event" /> */}
            <Button onClick={() => router.push('/newEvent')}>
              <FcPlus style={{ marginRight: 8 }} />
              New Event
            </Button>
            <EventFilter />
            <EventTable
              data={data}
              isLoading={isLoading || isFetching}
              refetch={refetch}
            />
          </TabPanel>
          <TabPanel>
            <GroupTrackingTable
              data={dataTrackingGroups}
              isLoading={isLoadingTrackingGroups || isFetchingTrackingGroups}
              refetch={refetchTrackingGroups}
            />
          </TabPanel>
          <TabPanel>
            <BatchList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
}

export { HomeContainer };
