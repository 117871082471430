import { useColorMode } from '@chakra-ui/color-mode';
import { Input } from '@chakra-ui/input';
import { Box, useToast } from '@chakra-ui/react';
import { CUIAutoComplete, CUIAutoCompleteProps } from 'chakra-ui-autocomplete';
import { useEffect, useState } from 'react';

export type Item = {
  label: string;
  value: string;
};

interface MultipleSelectProps extends CUIAutoCompleteProps<Item> {
  onChange?: (val: string) => void;
  getSelectedItems: (data: Array<Item>) => void;
  singleItem?: boolean;
  defaultValue?: Array<Item>;
  readOnly?: boolean;
  value?: Array<Item>;
  onClick?: () => void;
}

function MultipleSelect(props: MultipleSelectProps) {
  const { colorMode } = useColorMode();
  const toast = useToast();

  const [selectedItems, setSelectedItems] = useState<Array<Item>>(() => {
    if (props.defaultValue) {
      return props.defaultValue;
    }
    return [];
  });

  const handleSelectedItemsChange = (items?: Array<Item>) => {
    if (items) {
      if (props.singleItem && selectedItems.length > 1) {
        toast({
          status: 'error',
          description: 'You can only select one item',
          duration: 3000,
          title: 'Error',
        });
        return;
      }

      setSelectedItems(items);
    }
  };

  useEffect(() => {
    props.getSelectedItems(selectedItems);
  }, [selectedItems]);

  return (
    <Box position="relative">
      <style global jsx>
        {`
          .css-efk3t2 {
            z-index: 10;
            position: absolute;
            top: 100% !important;
            max-height: 300px;
            overflow-y: auto;
          }
          ul[role='listbox'] {
            background-color: ${colorMode !== 'light' ? '#2E3649' : 'white'};
            border-color: '#5F6774';
          }
          li[role='option']:hover {
            background-color: ${colorMode !== 'light' ? '#212736' : ''};
            cursor: pointer;
          }
        `}
      </style>
      <CUIAutoComplete
        getA11yRemovalMessage={() => {
          if (props.getA11yRemovalMessage) {
            props.getA11yRemovalMessage;
          }
          return '';
        }}
        onSelectedItemsChange={(changes) =>
          handleSelectedItemsChange(changes.selectedItems)
        }
        renderCustomInput={(inputProps) => (
          <>
            <Input
              {...inputProps}
              onClick={props.onClick}
              onChange={(val) => {
                inputProps.onChange(val);
                if (props.onChange) {
                  props.onChange(val.target.value);
                }
              }}
            />
          </>
        )}
        selectedItems={selectedItems}
        disableCreateItem
        {...props}
      />
    </Box>
  );
}

export { MultipleSelect };
