import { useMemo } from 'react';
import { useGetBatchList } from 'modules/batch/batchHooks';
import { VStack, Heading, Text } from '@chakra-ui/react';
import { Column } from 'react-table';
import { BatchDetail } from 'modules/batch/batch';
import dayjs from 'dayjs';
import Link from 'next/link';

import { CustomTable } from '../Component/Table';
import { BadgeBatchStatus } from '../Component/BadgeBatchStatus';

type Data = Partial<BatchDetail>;

export function BatchList() {
  const { data, isLoading } = useGetBatchList({
    limit: 10,
    page: 1,
    createdBy: [],
    batchId: [],
  });

  const columns = useMemo<Array<Column<Data>>>(
    () => [
      {
        Header: 'Event Name',
        accessor: 'name',
        maxWidth: 250,
        Cell: ({ cell }) => (
          <Link href={`/batch/${cell.row.original.batchId}`}>
            <a> {cell.value}</a>
          </Link>
        ),
      },
      {
        Header: 'Status',
        accessor: 'batchStatus',
        maxWidth: 250,
        Cell: ({ cell: { value } }) => <BadgeBatchStatus value={value} />,
      },
      {
        Header: 'Status',
        accessor: 'createdAt',
        maxWidth: 250,
        Cell: ({ cell: { value } }) => (
          <Text minW="200">{dayjs(value).format('DD MMMM YYYY')}</Text>
        ),
      },
    ],
    [],
  );
  return (
    <VStack>
      <Heading>Batch List</Heading>
      <CustomTable<Data>
        metadata={data?.metadata}
        data={data?.batches || []}
        columns={columns}
        isLoading={isLoading}
      />
    </VStack>
  );
}
