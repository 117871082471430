import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';

import {
  CreateSchemaRequest,
  GetBatchesResponse,
  GetBatchRequest,
  GetBatchByIDFilterRequest,
  GetBatchByIDResponse,
  CreateBatchReviewRequest,
} from './batch';
import {
  createSchema,
  CreateSchemaReqError,
  getBatches,
  GetBatchesError,
  getBatchByID,
  GetBatchByIDError,
  createBatchReview,
  CreateBatchReviewError,
} from './batchService';

function useCreateBulkEvent(
  options?: UseMutationOptions<
    unknown,
    CreateSchemaReqError,
    CreateSchemaRequest
  >,
) {
  return useMutation(
    (payload: CreateSchemaRequest) => createSchema(payload),
    options,
  );
}

type GetBatchKey = Array<string | GetBatchRequest>;

function useGetBatchList<TData = GetBatchesResponse>(
  payload: GetBatchRequest,
  options?: UseQueryOptions<
    GetBatchesResponse,
    GetBatchesError,
    TData,
    GetBatchKey
  >,
) {
  return useQuery(
    ['getBatchList', payload],
    () => getBatches(payload),
    options,
  );
}

type GetBatchByIDKey = Array<string | GetBatchByIDFilterRequest>;

function useGetBatchByID<TData = GetBatchByIDResponse>(
  payload: GetBatchByIDFilterRequest,
  options?: UseQueryOptions<
    GetBatchByIDResponse,
    GetBatchByIDError,
    TData,
    GetBatchByIDKey
  >,
) {
  return useQuery(
    ['getBatchByID', payload],
    () => getBatchByID(payload),
    options,
  );
}

function useCreateBatchReview(
  options?: UseMutationOptions<
    unknown,
    CreateBatchReviewError,
    CreateBatchReviewRequest
  >,
) {
  return useMutation(
    (payload: CreateBatchReviewRequest) => createBatchReview(payload),
    options,
  );
}

export {
  useCreateBulkEvent,
  useCreateBatchReview,
  useGetBatchList,
  useGetBatchByID,
};
