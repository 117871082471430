import {
  CloseButton,
  HStack,
  Img,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Center,
  Checkbox,
  VStack,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { useState } from 'react';

type Props = {
  images: Array<string>;
  onDelete?: (index: number) => void;
  onSelectedThumbnail?: (url: string) => void;
};

function LightBox({ images, onDelete, onSelectedThumbnail }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  return (
    <HStack>
      {images.length > 0 &&
        images.map((url, indexImage) => (
          <VStack key={url}>
            {onSelectedThumbnail && (
              <Checkbox
                onChange={() => {
                  setSelectedThumbnail(indexImage);
                  onSelectedThumbnail(url);
                }}
                isChecked={selectedThumbnail === indexImage}
              >
                Set as thumbnail
              </Checkbox>
            )}
            <HStack position="relative">
              {onDelete && (
                <CloseButton
                  position="absolute"
                  right={0}
                  top={0}
                  boxSize="1.5rem"
                  color="white"
                  borderRadius="full"
                  bg="green.main"
                  onClick={() => {
                    onDelete(indexImage);
                  }}
                />
              )}
              <Img
                cursor="pointer"
                src={url}
                w="200px"
                h="200px"
                onClick={() => {
                  setSelectedIndex(indexImage);
                  onOpen();
                }}
              />
            </HStack>
          </VStack>
        ))}
      <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Img cursor="pointer" src={images[selectedIndex]} />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  );
}

export { LightBox };
