import { Page } from 'uikit/layout/Page';
import { Box } from '@chakra-ui/layout';
import type { NextPage } from 'next';

import { HomeContainer } from '@/uikit/Home/HomeContainer';

const Home: NextPage = () => {
  return (
    <Page>
      <Box justifyContent="center">
        <HomeContainer />
      </Box>
    </Page>
  );
};

export default Home;
