import { Badge } from '@chakra-ui/layout';

import { BatchStatus } from '@/modules/batch/batch';

type Props = {
  value?: BatchStatus;
};

function BadgeBatchStatus({ value }: Props) {
  switch (value) {
    case 'BATCH_WAITING_FOR_APPROVAL':
      return (
        <Badge variant="solid" colorScheme="yellow" fontSize="xs" padding="7px">
          Batch Waiting For Approval
        </Badge>
      );
    case 'BATCH_APPROVED':
      return (
        <Badge variant="solid" colorScheme="green" fontSize="xs" padding="7px">
          Batch Approved
        </Badge>
      );
    case 'BATCH_REJECTED':
      return (
        <Badge variant="solid" colorScheme="red" fontSize="xs" padding="7px">
          Batch Rejected
        </Badge>
      );
    default:
      return <Badge variant="outline">Something Went Wrong</Badge>;
  }
}

export { BadgeBatchStatus };
