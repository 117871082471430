import { Text, VStack } from '@chakra-ui/layout';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Column } from 'react-table';
import Link from 'next/link';
import { AllEventResponse, Event, Metadata } from 'modules/event/event';

import { CustomTable } from '../Component/Table';
import { LightBox } from '../Component/LightBox';
import { BadgeStatus } from '../Component/BadgeStatus';

type Props = {
  data?: AllEventResponse;
  isLoading: boolean;
  refetch: () => void;
};
type Data = Partial<Event> & { action: null; edit: null };

function EventTable({ data, isLoading }: Props) {
  const customData: Array<Data> =
    data?.events.map((event) => {
      return {
        ...event,
        action: null,
        edit: null,
      };
    }) ?? [];

  const columns = useMemo<Array<Column<Data>>>(
    () => [
      {
        Header: 'Event Name',
        accessor: (row) => row,
        maxWidth: 300,
        Cell: ({ cell: value }: { cell: { value: Event } }) => {
          return (
            <VStack alignItems="flex-start">
              <BadgeStatus
                value={value.value?.latestProposalStatus || 'NOT_CREATED_YET'}
              />
              <Link passHref href={`/event/${value.value.id}`}>
                <Text cursor="pointer" wordBreak="break-word">
                  {value.value.name}
                </Text>
              </Link>
            </VStack>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ cell: { value } }) => <Text minW="200">{value}</Text>,
        maxWidth: 200,
      },
      {
        Header: 'IsLearning',
        accessor: 'isLearning',
        Cell: (value) => {
          if (value.cell.value === 'IsLearningTrue') {
            return <h4>Is Learning</h4>;
          }
          return <h4>Not Is Learning</h4>;
        },
      },
      {
        Header: 'Created By',
        accessor: (row) => row.metadata,
        maxWidth: 200,
        Cell: ({ cell: value }: { cell: { value: Metadata } }) => (
          <Text minW="200">{value.value.metaDataHistory?.createdBy}</Text>
        ),
      },
      {
        Header: 'Created At',
        maxWidth: 200,
        accessor: (row) => row.metadata,
        Cell: ({ cell: value }: { cell: { value: Metadata } }) => (
          <Text minW="200">
            {dayjs(value?.value.metaDataHistory?.createdAt).format(
              'DD MMMM YYYY',
            )}
          </Text>
        ),
      },
      {
        Header: 'Thumbnail',
        accessor: 'thumbnail',
        Cell: (value) => {
          if (value.cell.value) {
            return <LightBox images={[value.cell.value]} />;
          }
          return <h4>No thumbnail</h4>;
        },
      },
    ],
    [],
  );

  return (
    <CustomTable<Data>
      metadata={data?.metadata}
      data={customData}
      columns={columns}
      isLoading={isLoading}
    />
  );
}

export { EventTable };
