import { Select, SelectProps } from '@chakra-ui/react';

function FilterStatusProposal(props: SelectProps) {
  return (
    <Select {...props}>
      <option value="ALL_STATUS">All</option>
      <option value="WAITING_FOR_APPROVAL">Waiting For Approval</option>
      <option value="SCHEMA_APPROVED">Schema Approved</option>
      <option value="WAITING_ON_QUEUE_STAGING">Waiting on Queue Staging</option>
      <option value="DEPLOY_STAGING_ONGOING">Deploy Staging On Going</option>
      <option value="IN_STAGING">In Staging</option>
      <option value="DEPLOY_STAGING_FAILED">Deploy Staging Failed</option>
      <option value="WAITING_ON_QUEUE_PRODUCTION">
        Waiting on Queue Production
      </option>

      <option value="DEPLOY_PROD_ONGOING">Deploy Production On Going</option>
      <option value="READY_FOR_PROD">Ready For Production</option>
      <option value="IN_PRODUCTION">In Production</option>
      <option value="DEPLOY_PROD_FAILED">Deploy Production Failed</option>
      <option value="CLOSED">Closed</option>
      <option value="NOT_CREATED_YET">Not Created</option>
    </Select>
  );
}

export { FilterStatusProposal };
