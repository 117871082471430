import { Badge } from '@chakra-ui/layout';

import { ProposalStatus } from '@/modules/proposal/proposal';

type Props = {
  value?: ProposalStatus;
};

function BadgeStatus({ value }: Props) {
  switch (value) {
    case 'IN_PRODUCTION':
    case 'IN_STAGING':
      return (
        <Badge variant="solid" colorScheme="green">
          {value === 'IN_PRODUCTION' ? 'In Production' : 'In Staging'}
        </Badge>
      );
    case 'DEPLOY_PROD_ONGOING':
    case 'DEPLOY_STAGING_ONGOING':
      return (
        <Badge variant="outline" colorScheme="yellow">
          {value === 'DEPLOY_PROD_ONGOING'
            ? 'Ongoing Deploying to Production '
            : 'Ongoing Deploying to Staging '}
        </Badge>
      );
    case 'WAITING_ON_QUEUE_STAGING':
    case 'WAITING_ON_QUEUE_PRODUCTION':
      return (
        <Badge variant="outline" colorScheme="purple">
          {value === 'WAITING_ON_QUEUE_PRODUCTION'
            ? 'Queue Deploying to Production '
            : 'Queue Deploying to Staging '}
        </Badge>
      );
    case 'DEPLOY_PROD_FAILED':
    case 'DEPLOY_STAGING_FAILED':
      return (
        <Badge variant="solid" colorScheme="red">
          {value === 'DEPLOY_PROD_FAILED'
            ? 'Deploy On Production Failed'
            : 'Deploy On Staging Failed'}
        </Badge>
      );
    case 'CLOSED':
      return (
        <Badge variant="solid" colorScheme="red">
          Closed
        </Badge>
      );
    case 'WAITING_FOR_APPROVAL':
      return (
        <Badge variant="solid" colorScheme="yellow">
          Waiting Approval
        </Badge>
      );
    case 'READY_FOR_PROD':
      return (
        <Badge variant="solid" colorScheme="yellow">
          Ready For Production
        </Badge>
      );
    case 'SCHEMA_APPROVED':
      return (
        <Badge variant="outline" colorScheme="green">
          Approved
        </Badge>
      );
    case 'NOT_CREATED_YET':
      return (
        <Badge variant="outline" colorScheme="orange">
          Not Created Yet
        </Badge>
      );
    default:
      return <Badge variant="outline">Something Went Wrong</Badge>;
  }
}

export { BadgeStatus };
