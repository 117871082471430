import { CommonCreateOrEditResponse } from '../event/event';

import {
  GetAutocompleteRequest,
  AutoCompleteResopnse,
  CreateProductRequest,
  CreateFeatureRequest,
} from './common';

import {
  getAutocompleteExn,
  GetAutocompleteError,
  createProductExn,
  createFeatureExn,
  CreateProductError,
  CreateFeatureError,
} from '@/__generated__/__services__/rg_data_cms_api/RgDataCmsApi';

const getAutoComplete = async (
  payload: GetAutocompleteRequest,
): Promise<AutoCompleteResopnse> => {
  return getAutocompleteExn(payload).then((res) => ({
    data: res.data.slice(0, 20),
  }));
};

const createProduct = async (
  payload: CreateProductRequest,
): Promise<CommonCreateOrEditResponse> => {
  return createProductExn(payload);
};

const createFeature = async (
  payload: CreateFeatureRequest,
): Promise<CommonCreateOrEditResponse> => {
  return createFeatureExn(payload);
};

export { getAutoComplete, createProduct, createFeature };

export type { GetAutocompleteError, CreateProductError, CreateFeatureError };
