import { GetAutocompleteRequest } from 'modules/common/common';
import { useGetAutoComplete } from 'modules/common/commonHooks';
import {
  HStack,
  VStack,
  FormLabel,
  Input,
  FormControl,
  Text,
  Select,
} from '@chakra-ui/react';
import { useDebounce } from '@react-hook/debounce';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { FilterLegacyMapping } from 'modules/event/event';

import { Item, MultipleSelect } from '../Component/MultipleSelect';
import { FilterStatusProposal } from '../Component/FilterStatusProposal';

type FormType = 'productName' | 'featureName' | 'createdBy' | 'eventName';
type Opt = {
  label: string;
  value: FilterLegacyMapping;
};

const options: Array<Opt> = [
  {
    label: 'All',
    value: 'FILTER_LEGACY_MAPPING_ALL',
  },
  {
    label: 'Yes',
    value: 'FILTER_LEGACY_MAPPING_TRUE',
  },
  {
    label: 'No',
    value: 'FILTER_LEGACY_MAPPING_FALSE',
  },
];

export function EventFilter() {
  const [autoCompleteParam, setAutoCompleteParam] = useDebounce<
    GetAutocompleteRequest | undefined
  >(undefined);
  const router = useRouter();
  const { handleSubmit, register } = useForm({
    defaultValues: {
      eventName: '',
    },
  });

  const { data: autoCompleteData } = useGetAutoComplete(
    autoCompleteParam || { input: '', param: 'AUTOCOMPLETE_PARAM_EVENT' },
    {
      enabled: autoCompleteParam !== undefined,
    },
  );

  const handleSubmitAutoComplete = (data: Array<Item>, type: FormType) => {
    if (data[0]) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, [type]: data[0].label },
      });
    } else {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, [type]: undefined },
      });
    }
  };

  return (
    <VStack>
      <HStack justifyContent={'center'} zIndex={4} left={0} top={0}>
        {/* Event Name Filter */}
        <form
          onSubmit={handleSubmit((e) => {
            router.replace({
              pathname: router.pathname,
              query: { ...router.query, eventName: e.eventName },
            });
          })}
        >
          <FormControl w="10rem">
            <FormLabel>Event Name</FormLabel>
            <Input
              type="text"
              placeholder="Event Name"
              {...register('eventName')}
            />
          </FormControl>
        </form>

        <MultipleSelect
          singleItem
          onClick={() => {
            setAutoCompleteParam({
              input: '',
              param: 'AUTOCOMPLETE_PARAM_PRODUCT',
            });
          }}
          items={autoCompleteData?.data || []}
          onChange={(val) =>
            setAutoCompleteParam({
              input: val,
              param: 'AUTOCOMPLETE_PARAM_PRODUCT',
            })
          }
          getSelectedItems={(data) =>
            handleSubmitAutoComplete(data, 'productName')
          }
          placeholder="Product Name"
          label="Product Name"
        />

        <MultipleSelect
          singleItem
          onClick={() => {
            setAutoCompleteParam({
              input: '',
              param: 'AUTOCOMPLETE_PARAM_FEATURE',
            });
          }}
          items={autoCompleteData?.data || []}
          onChange={(val) => {
            setAutoCompleteParam({
              input: val,
              param: 'AUTOCOMPLETE_PARAM_FEATURE',
            });
          }}
          getSelectedItems={(data) =>
            handleSubmitAutoComplete(data, 'featureName')
          }
          placeholder="Feature Name"
          label="Feature Name"
        />
        <MultipleSelect
          singleItem
          onClick={() => {
            setAutoCompleteParam({
              input: '',
              param: 'AUTOCOMPLETE_PARAM_OWNER',
            });
          }}
          items={autoCompleteData?.data || []}
          onChange={(val) =>
            setAutoCompleteParam({
              input: val,
              param: 'AUTOCOMPLETE_PARAM_OWNER',
            })
          }
          getSelectedItems={(data) =>
            handleSubmitAutoComplete(data, 'createdBy')
          }
          placeholder="Created By"
          label="Created By"
        />

        <VStack>
          <FormLabel>Filter</FormLabel>
          <FilterStatusProposal
            width={200}
            defaultValue={router.query.status}
            onChange={(e) => {
              router.replace({
                pathname: router.pathname,
                query: { ...router.query, eventStatus: e.target.value },
              });
            }}
          />
        </VStack>

        {/* Legacy Mapping Dropdown */}
        <VStack>
          <Text fontWeight="semibold">Select Legacy Mapping</Text>
          <Select
            onChange={(e) =>
              router.replace({
                pathname: router.pathname,
                query: { ...router.query, isLegacy: e.target.value },
              })
            }
          >
            {options.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </VStack>
      </HStack>
    </VStack>
  );
}
